<template>
  <v-menu :close-on-content-click="false" transition="scale-transition" absolute
    min-width="auto" :close-on-click="true">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field :value="formatedDate" :label="label" prepend-icon="mdi-calendar" v-on="on" v-bind="attrs" :required="required" @change="(newValue) => formatedDate = newValue"
        persistent-placeholder
        clearable :error-messages="errorMessages"
        :error="error" />
    </template>
    <v-date-picker :value="datePickerDate" @input="(newValue) => datePickerDate = newValue" no-title scrollable :min="getMin" :max="getMax">
    </v-date-picker>
  </v-menu>
</template>

<script>

import { format, parse, isValid } from "date-fns";
import { checkRules as utilsCheckRules } from "../../shared/utils/inputRulesUtils";

// v-date-picker accepts ISO 8601 date strings (YYYY-MM-DD)
const VUEJS_DATE_FORMAT = "yyyy-MM-dd";

export default {
  name: "DatePicker",
  props: {
    value: String,
    defaultNow: {
      type: Boolean,
      default: true
    },
    label: String,
    rules: {
      type: Array,
    },
    min: {
      type: Date
    },
    max: {
      type: Date
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateFormat: this.$store.getters["parameterModule/getDateFormat"],
      errorMessages: null,
      error: false
    }
  },
  methods: {
    saveDate(newValue) {

      // extra check to make sure the user input is valid
      const newDate = new Date(newValue)
      if (isValid(newDate)) {
        newValue = format(newDate, VUEJS_DATE_FORMAT)
      } else {
        newValue = null
      }

      this.$emit('input', newValue)
      this.checkRules(newValue)
    },
    checkRules(newValue) {
      const result = utilsCheckRules(newValue, this.getRules)
      this.errorMessages = result.messages
      this.error = result.error
    }
  },
  created() {

    if (this.defaultNow && !this.value) {
      return this.saveDate(format(new Date(), VUEJS_DATE_FORMAT))
    }
  },
  computed: {
    getMax() {
      return this.max ? format(this.max, VUEJS_DATE_FORMAT) : undefined;
    },
    getMin() {
      return this.min ? format(this.min, VUEJS_DATE_FORMAT) : undefined;
    },
    getRules() {
      if (this.rules) {
        return this.rules
      }

      let rules = []

      if (this.required) {
        rules.push(v => !!v || "Datum moet ingevuld worden")
      }

      rules.push(v => (!v || isValid(new Date(v))) || `Geef een geldige datum in`)

      if (this.min) {
        rules.push(v => (!v || new Date(v) >= this.min) || `Datum kan niet voor ${format(this.min, this.dateFormat)} zijn`)
      }

      if (this.max) {
        rules.push(v => (!v || new Date(v) <= this.max) || `Datum kan niet na ${format(this.max, this.dateFormat)} zijn`)
      }
      return rules
    },
    calendarDate: {
      set(newValue) {
        this.saveDate(newValue)
      },
      get() {
        return this.value
      }
    },
    formatedDate: {
      set(newValue) {
        const parsedDate = parse(newValue, this.dateFormat, new Date())
        if (isValid(parsedDate)) {
          this.calendarDate = format(parsedDate, VUEJS_DATE_FORMAT)
        } else {
          this.calendarDate = newValue
        }
      },
      get() {
        if (this.calendarDate) {
          const parsedDate = parse(this.calendarDate, VUEJS_DATE_FORMAT, new Date())
          if (isValid(parsedDate)) {
            return format(parsedDate, this.dateFormat)
          }
        }
        return null
      }
    },
    datePickerDate: {
      get() {
        if (isValid(this.calendarDate)) {
          return this.calendarDate
        } else {
          return null
        }
      },
      set(newValue) {
        this.calendarDate = newValue
      }
    }
  },
  watch: {
    min: {
      handler: function (newValue, oldValue) {
        if (newValue?.getTime() == oldValue?.getTime()) {
          return
        }

        if (this.getMin > this.value) {
          this.calendarDate = this.getMin
        } else {
          this.checkRules(this.value)
        }
      },
    },
  }
}
</script>
